import React from "react";

const offsetWidth = 25;

export default function trebleClef({ linesAbove, linesBelow }) {
  return (
    <svg width="539" height="225" xmlns="http://www.w3.org/2000/svg">
      <title>Music Staff</title>
      <g>
        <title>Layer 1</title>
        <g transform="matrix(3.00922 0 0 1 -256.89 -272.433)" id="g846">
          <path
            fill="none"
            stroke="currentColor"
            strokeWidth="5.28575"
            id="path838"
            d="m85.367645,339.434662l0,89.063995"
          />
          {linesAbove.map((la, offset) => (
            <React.Fragment key={offset}>
              <path
                fill="none"
                stroke="currentColor"
                visibility={la > 2 ? "visible" : "hidden"}
                strokeWidth="1pt"
                id="above3"
                d={`m${113 + offset * offsetWidth},274.35775l15,0.72`}
              />
              <path
                fill="none"
                stroke="currentColor"
                visibility={la > 1 ? "visible" : "hidden"}
                strokeWidth="1pt"
                id="above2"
                d={`m${113 + offset * offsetWidth},296.2885l15,0.72`}
              />
              <path
                fill="none"
                stroke="currentColor"
                visibility={la > 0 ? "visible" : "hidden"}
                strokeWidth="1pt"
                id="above1"
                d={`m${113 + offset * offsetWidth},318.21925l15,0.72`}
              />
            </React.Fragment>
          ))}
          <path
            fill="none"
            stroke="currentColor"
            strokeWidth="1pt"
            id="path839"
            d="m86,340.150l177,0.72"
          />
          <path
            fill="none"
            stroke="currentColor"
            strokeWidth="1pt"
            id="path840"
            d="m86,362.08075l177,0.72"
          />
          <path
            fill="none"
            stroke="currentColor"
            strokeWidth="1pt"
            id="path842"
            d="m86,384.0115l177,0.72"
          />
          <path
            fill="none"
            stroke="currentColor"
            strokeWidth="1pt"
            id="path844"
            d="m86,405.94225l177,0.72"
          />
          <path
            fill="none"
            stroke="currentColor"
            strokeWidth="1pt"
            id="path845"
            d="m86,427.873l177,0.72"
          />
          {linesBelow.map((lb, offset) => (
            <React.Fragment key={offset}>
              <path
                fill="none"
                stroke="currentColor"
                visibility={lb > 0 ? "visible" : "hidden"}
                strokeWidth="1pt"
                id="below1"
                d={`m${113 + offset * offsetWidth},449.80375l15,0.72`}
              />
              <path
                fill="none"
                stroke="currentColor"
                visibility={lb > 1 ? "visible" : "hidden"}
                strokeWidth="1pt"
                id="below2"
                d={`m${113 + offset * offsetWidth},471.7345l15,0.72`}
              />
              <path
                fill="none"
                stroke="currentColor"
                visibility={lb > 2 ? "visible" : "hidden"}
                strokeWidth="1pt"
                id="below3"
                d={`m${113 + offset * offsetWidth},493.66525l15,0.72`}
              />
            </React.Fragment>
          ))}
        </g>
        <g
          transform="matrix(0.907005 0 0 0.886818 -219.19 56.14968)"
          id="g1981"
        >
          <path
            fill="currentColor"
            stroke="currentColor"
            id="path1204"
            d="m272.8367,137.435272c0.021912,8.470001 18.815918,7.393997 18.753418,-6.572998c0.02478,-13.606995 -17.95462,-89.745483 -18.155304,-104.306391c-0.211395,-11.556797 6.122284,-24.509529 11.548401,-24.368599c4.298584,0.141 8.337585,9.8729 8.315399,21.986399c0.326385,39.172806 -33.980804,36.640701 -33.980804,63.408791c0,17.301804 14.363403,21.871811 21.545105,21.871811c24.319794,-0.163002 20.239197,-29.379791 4.733276,-29.379791c-9.140289,-0.326614 -17.138092,12.078186 -5.059784,22.360786c-20.402496,-8.976799 -9.466705,-30.685005 6.691986,-30.848305c21.545105,0.163101 25.299225,39.662308 -6.365479,39.82531c-18.933594,0.163994 -26.931381,-16.484901 -26.768204,-29.542503c0,-25.135891 37.05101,-39.662491 36.561386,-60.881098c0,-13.384 -13.873779,-7.344898 -14.200195,14.200205c0.326508,13.384102 17.627808,84.058395 17.4646,94.994385c0,22.523987 -28.889984,15.179001 -28.889984,3.753998c0.163177,-16.321991 16.974884,-4.734009 11.751892,-4.244003c-4.080597,7.181992 -3.945709,7.742004 -3.945709,7.742004z"
          />
          <path
            fill="currentColor"
            stroke="currentColor"
            id="path1205"
            d="m280.664215,130.339279c0,3.788986 -3.160095,6.86499 -7.053406,6.86499c-3.893311,0 -7.053497,-3.076004 -7.053497,-6.86499c0,-3.790009 3.160187,-6.865997 7.053497,-6.865997c3.893311,0 7.053406,3.075989 7.053406,6.865997z"
          />
          <path
            fill="currentColor"
            id="path1206"
            d="m288.325806,107.929283l2.3125,0l0,2.249992l-2.3125,0l0,-2.249992z"
          />
          <path
            fill="currentColor"
            id="path1207"
            d="m281.575806,72.617279l2.9375,0l0,7.3125l-2.9375,0l0,-7.3125z"
          />
          <path
            fill="currentColor"
            id="path1208"
            d="m276.36792,45.45649l1.393005,-0.240501l1.960266,12.937103l-1.392975,0.240498l-1.960297,-12.937099z"
          />
        </g>
      </g>
    </svg>
  );
}
