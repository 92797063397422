import React from "react";
import { ReactComponent as WholeNoteSVG } from "./svg/whole-note.svg";
import { ReactComponent as SharpSVG } from "./svg/sharp.svg";

const offsetWidth = 75;

const NOTE_NAMES = "CDEFGAB".split("");
const NOTES = [];
for (let octave = -1; octave <= 9; octave++) {
  for (const noteName of NOTE_NAMES) {
    NOTES.push(`${octave}${noteName}`);
  }
}

const getDistanceBetweenNotes = (
  { name: name1, octave: octave1 },
  { name: name2, octave: octave2 }
) => {
  const note1 = `${octave1}${name1}`;
  const note2 = `${octave2}${name2}`;
  if (note1 === note2) {
    return 0;
  }
  return NOTES.indexOf(note2) - NOTES.indexOf(note1);
};

const getNoteTopPosition = ({ name, octave }) => {
  const ROOT_POSITION = 170;
  const INTERVAL_DISTANCE = 11;
  const distance = getDistanceBetweenNotes(
    { name: "C", octave: 4 },
    { name: name.charAt(0), octave }
  );
  let vPos = ROOT_POSITION - distance * INTERVAL_DISTANCE;
  return `${vPos}px`;
};

const getNoteStyle = ({ note: { name, octave }, offset = 0 }) => {
  return {
    position: "absolute",
    top: getNoteTopPosition({ name, octave }),
    left: `${90 + offset * offsetWidth}px`,
    opacity: `${Math.max((100 - offset * 40) / 100, 0)}`,
    height: "28px",
  };
};

const getSharpStyle = ({ name, octave }) => ({
  position: "absolute",
  top: getNoteTopPosition({ name, octave }),
  marginTop: "-7px",
  left: "80px",
  height: "28px",
});

export default function WholeNote({ note, isSharp, offset, style, ...props }) {
  return (
    <>
      <WholeNoteSVG
        style={{ ...getNoteStyle({ note, offset }), ...style }}
        {...props}
      />
      {isSharp && <SharpSVG style={getSharpStyle(note)} />}
    </>
  );
}
