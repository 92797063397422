import React, { useState, useEffect } from "react";
import { Client as Styletron } from "styletron-engine-atomic";
import { Provider as StyletronProvider } from "styletron-react";
import { LightTheme, BaseProvider, styled } from "baseui";
import { Select } from "baseui/select";
import { Slider } from "baseui/slider";
import { Checkbox, STYLE_TYPE } from "baseui/checkbox";
import { StatefulPopover } from "baseui/popover";
import { Block } from "baseui/block";
import { Button, KIND } from "baseui/button";
import { Piano, KeyboardShortcuts } from "react-piano";
import "react-piano/dist/styles.css";
import WebMidi from "webmidi";
import TrebleClef from "./treble-clef";
import WholeNote from "./whole-note";
import { demoNotes } from "./demos";
import "./App.css";

const engine = new Styletron();

const allNotes = demoNotes;
let activeNotes = [];

let currentDemoNote = 0;
let currentDemoNoteDirection = null;
let demoInterval = null;

const INITIAL_MIN_NOTE_INDEX = 5;
const INITIAL_MAX_NOTE_INDEX = 19;

let minNoteIndex = INITIAL_MIN_NOTE_INDEX;
let maxNoteIndex = INITIAL_MAX_NOTE_INDEX;

let minNote = allNotes[minNoteIndex][0];
let maxNote = allNotes[maxNoteIndex][0];

const SelectWrapper = styled("div", {
  display: "flex",
  alignItems: "center",
  columnGap: "10px",
});

const SelectLabel = styled("div");

const addActiveNote = (note, activeNotes) => {
  if (
    activeNotes.some((activeNote) => {
      return activeNote.number === note.number;
    })
  ) {
    return activeNotes;
  }

  return [...activeNotes, note];
};

const removeActiveNote = (note, activeNotes) => {
  return activeNotes.filter((activeNote) => {
    return activeNote.number !== note.number;
  });
};

const getNextDemoNotes = (notesArr) => {
  if (currentDemoNoteDirection === null) {
    currentDemoNoteDirection = "up";
  }
  if (currentDemoNoteDirection === "up") {
    if (currentDemoNote < maxNoteIndex) {
      currentDemoNote++;
      if (currentDemoNote < minNoteIndex) {
        currentDemoNote = minNoteIndex;
      }
    } else {
      currentDemoNoteDirection = "down";
      currentDemoNote--;
      if (currentDemoNote > maxNoteIndex) {
        currentDemoNote = maxNoteIndex;
      }
    }
  } else if (currentDemoNoteDirection === "down") {
    if (currentDemoNote > minNoteIndex) {
      currentDemoNote--;
      if (currentDemoNote > maxNoteIndex) {
        currentDemoNote = maxNoteIndex;
      }
    } else {
      currentDemoNoteDirection = "up";
      currentDemoNote++;
      if (currentDemoNote < minNoteIndex) {
        currentDemoNote = minNoteIndex;
      }
    }
  }
  return notesArr[currentDemoNote];
};

function NoteName({ note }) {
  return (
    <>
      <strong>{note.name}</strong>
      {note.octave}
      <br />
    </>
  );
}

function RangeSlider({
  allAvailableNotes,
  availableNotesIndices,
  setAvailableNotesIndices,
}) {
  const rangeMax = allAvailableNotes.length - 1;
  return (
    <Slider
      value={availableNotesIndices}
      max={rangeMax}
      onChange={({ value }) => {
        minNoteIndex = value[0];
        maxNoteIndex = value[1];
        minNote = allAvailableNotes[minNoteIndex][0];
        maxNote = allAvailableNotes[maxNoteIndex][0];
        return value && setAvailableNotesIndices(value);
      }}
      overrides={{
        ThumbValue: ({ $value }) => {
          const note1 = allAvailableNotes[$value[0]][0];
          const note2 = allAvailableNotes[$value[1]][0];
          return (
            <div
              style={{
                position: "absolute",
                marginTop: "-25px",
                width: "60px",
                textAlign: "center",
              }}
            >
              {`${note1.name}${note1.octave}-${note2.name}${note2.octave}`}
            </div>
          );
        },
        TickBar: ({ $min, $max }) => {
          const minNote = allAvailableNotes[$min][0];
          const maxNote = allAvailableNotes[$max][0];
          return (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div>{`${minNote.name}${minNote.octave}`}</div>
              <div>{`${maxNote.name}${maxNote.octave}`}</div>
            </div>
          );
        },
      }}
    />
  );
}

function AppWrapper() {
  return (
    <StyletronProvider value={engine}>
      <BaseProvider theme={LightTheme}>
        <App />
      </BaseProvider>
    </StyletronProvider>
  );
}

function clearMidiListeners() {
  if (WebMidi && WebMidi.inputs) {
    WebMidi.inputs.forEach((input) => {
      input.removeListener();
    });
  }
}

function initConnection({ setNotes, activeInput = 0, isDemoMode }) {
  // console.log("WebMidi enabled!");
  // console.log(WebMidi.inputs);
  // console.log(WebMidi.outputs);

  if (demoInterval) {
    clearInterval(demoInterval);
  }
  setNotes([]);

  if (isDemoMode) {
    demoInterval = setInterval(() => {
      setNotes(getNextDemoNotes(demoNotes));
    }, 50);
  }

  const input = WebMidi.inputs[activeInput];
  if (input) {
    clearMidiListeners();
    input.addListener("noteon", "all", (evt) => {
      activeNotes = addActiveNote(evt.note, activeNotes);
      setNotes(activeNotes);
    });
    input.addListener("noteoff", "all", (evt) => {
      activeNotes = removeActiveNote(evt.note, activeNotes);
      setNotes(activeNotes);
    });
    // eslint-disable-next-line no-undef
    gtag("event", "MIDI Connected", {
      event_category: "MIDI",
    });
  }
}

function getNoteFromNumber(number) {
  const notes = WebMidi._notes;
  return {
    number,
    name: notes[number % 12],
    octave: WebMidi.getOctave(number),
  };
}

function updateExtraStaffLineCounts({
  notes,
  linesAbove,
  setLinesAbove,
  linesBelow,
  setLinesBelow,
}) {
  const offsetArr = [];
  notes.forEach((note) => {
    let nextLinesAbove = 0;
    let nextLinesBelow = 0;
    let offset = note.offset;
    if (!offset) {
      offset = 0;
    }
    if (Array.isArray(offsetArr[offset])) {
      [nextLinesAbove, nextLinesBelow] = offsetArr[offset];
    }
    if (note.number >= 81 && nextLinesAbove < 1) {
      nextLinesAbove = 1;
    }
    if (note.number >= 84 && nextLinesAbove < 2) {
      nextLinesAbove = 2;
    }
    if (note.number >= 88 && nextLinesAbove < 3) {
      nextLinesAbove = 3;
    }
    if (note.number <= 61 && nextLinesBelow < 1) {
      nextLinesBelow = 1;
    }
    if (note.number <= 58 && nextLinesBelow < 2) {
      nextLinesBelow = 2;
    }
    if (note.number <= 54 && nextLinesBelow < 3) {
      nextLinesBelow = 3;
    }
    offsetArr[offset] = [nextLinesAbove, nextLinesBelow];
  });
  const nextLinesAbove = [];
  const nextLinesBelow = [];
  offsetArr.forEach((offsetPair, offsetI) => {
    nextLinesAbove.push(offsetPair[0]);
    nextLinesBelow.push(offsetPair[1]);
  });
  if (nextLinesAbove.join(",") !== linesAbove.join(",")) {
    setLinesAbove(nextLinesAbove);
  }
  if (nextLinesBelow.join(",") !== linesBelow.join(",")) {
    setLinesBelow(nextLinesBelow);
  }
}

function notesNumStringFromNotesArray(notes) {
  return notes
    .map((note) => note.number)
    .sort()
    .join(",");
}

const notesPlayed = [];

function logNotesPlayed(note, isCorrect, setStats) {
  notesPlayed.push({
    date: new Date(),
    note,
    isCorrect,
  });
  if (notesPlayed.length > 1) {
    const avgTime =
      (notesPlayed[notesPlayed.length - 1].date - notesPlayed[0].date) /
      (notesPlayed.length - 1) /
      1000;
    const correctNotes = notesPlayed.filter((note) => note.isCorrect).length;
    const accuracy = (correctNotes / notesPlayed.length) * 100;
    setStats({
      avgTime: new Intl.NumberFormat("en-US", {
        maximumSignificantDigits: 2,
      }).format(avgTime),
      accuracy: new Intl.NumberFormat("en-US", {
        maximumSignificantDigits: 2,
      }).format(accuracy),
      notesPlayed: notesPlayed.length,
    });
  }
}

function resetStats(setStats) {
  notesPlayed.splice(0, notesPlayed.length);
  setStats(null);
}

const getRandomNote = (availableNotesIndices) => {
  const nextNoteIndex =
    Math.floor(
      Math.random() * (1 + availableNotesIndices[1] - availableNotesIndices[0])
    ) + availableNotesIndices[0];
  return allNotes[nextNoteIndex];
};

function App() {
  const [notes, setNotes] = useState([]);
  const [showNoteName, setShowNoteName] = useState(false);
  const [demoMode, setDemoMode] = useState(false);
  const [availableNotesIndices, setAvailableNotesIndices] = React.useState([
    INITIAL_MIN_NOTE_INDEX,
    INITIAL_MAX_NOTE_INDEX,
  ]);
  const [linesAbove, setLinesAbove] = useState([0]);
  const [linesBelow, setLinesBelow] = useState([0]);
  const [notesToPlay, setNotesToPlay] = useState([
    getRandomNote(availableNotesIndices),
    getRandomNote(availableNotesIndices),
    getRandomNote(availableNotesIndices),
  ]);
  const [playedCorrectly, setPlayedCorrectly] = useState(false);
  const [stats, setStats] = useState(null);
  const [activeInput, setActiveInput] = useState(0);
  useEffect(() => {
    initConnection({ activeInput, setNotes, demoMode });
  }, [activeInput, demoMode]);

  useEffect(() => {
    if (
      notesNumStringFromNotesArray(notes) ===
        notesNumStringFromNotesArray(notesToPlay[0]) &&
      !playedCorrectly
    ) {
      logNotesPlayed(notes, true, setStats);
      setPlayedCorrectly(true);
    }
    if (
      notes.length &&
      !playedCorrectly &&
      notesNumStringFromNotesArray(notes) !==
        notesNumStringFromNotesArray(notesToPlay[0])
    ) {
      logNotesPlayed(notes, false, setStats);
    }
  }, [notes, notesToPlay, playedCorrectly]);
  useEffect(() => {
    if (notes.length === 0 && playedCorrectly) {
      setPlayedCorrectly(false);
      setNotesToPlay([
        ...notesToPlay.slice(1),
        getRandomNote(availableNotesIndices),
      ]);
    }
  }, [availableNotesIndices, notes, notesToPlay, playedCorrectly]);
  useEffect(() => {
    const notesWithOffsets = notesToPlay
      .flat(2)
      .map((note, idx) => ({ ...note, offset: idx }));
    updateExtraStaffLineCounts({
      notes: [...notes, ...notesWithOffsets],
      linesAbove,
      setLinesAbove,
      linesBelow,
      setLinesBelow,
    });
  }, [notes, notesToPlay, linesAbove, linesBelow]);

  useEffect(() => {
    resetStats(setStats);
    setNotesToPlay(
      notesToPlay.map((notesToPlayArr) => {
        return notesToPlayArr.map((noteToPlay) => {
          if (
            noteToPlay.number >= minNote.number &&
            noteToPlay.number <= maxNote.number
          ) {
            return noteToPlay;
          }
          return getRandomNote(availableNotesIndices)[0];
        });
      })
    );
  }, [availableNotesIndices]);

  WebMidi.enable(function (err) {
    if (err) {
      console.log("WebMidi could not be enabled.", err);
    } else {
      initConnection({ activeInput, setNotes, demoMode });
    }
    WebMidi.addListener("connected", () => {
      initConnection({ activeInput, setNotes, demoMode });
    });
    WebMidi.addListener("disconnected", () => {
      initConnection({ activeInput, setNotes, demoMode });
    });
  });

  const keyboardShortcuts = KeyboardShortcuts.create({
    firstNote: minNote.number,
    lastNote: maxNote.number,
    keyboardConfig: [
      ...KeyboardShortcuts.BOTTOM_ROW,
      ...KeyboardShortcuts.QWERTY_ROW,
    ],
  });

  return (
    <>
      <Block position="absolute" $style={{ right: 0, zIndex: 1 }}>
        <StatefulPopover
          content={
            <Block padding="20px" width="500px">
              <RangeSlider
                allAvailableNotes={demoNotes}
                availableNotesIndices={availableNotesIndices}
                setAvailableNotesIndices={setAvailableNotesIndices}
              />
              <Checkbox
                checked={showNoteName}
                onChange={(e) => setShowNoteName(e.target.checked)}
                checkmarkType={STYLE_TYPE.toggle_round}
                overrides={{
                  Root: {
                    style: {
                      display: "block",
                      marginTop: "20px",
                    },
                  },
                }}
              >
                Show Note Name
              </Checkbox>
              {WebMidi.inputs.length && (
                <SelectWrapper>
                  <SelectLabel>MIDI&nbsp;Interface:</SelectLabel>
                  <Select
                    clearable={false}
                    options={WebMidi.inputs.map((input, i) => ({
                      label: `${input.manufacturer} - ${input.name}`,
                      id: i,
                    }))}
                    onChange={(params) => {
                      setActiveInput(params.value[0].id);
                    }}
                    value={[{ id: activeInput }]}
                  />
                </SelectWrapper>
              )}
              <Checkbox
                checked={demoMode}
                onChange={(e) => setDemoMode(e.target.checked)}
                checkmarkType={STYLE_TYPE.toggle_round}
                overrides={{
                  Root: {
                    style: {
                      display: "none",
                      marginTop: "20px",
                    },
                  },
                }}
              >
                Demo Mode
              </Checkbox>
            </Block>
          }
        >
          <Button
            kind={KIND.secondary}
            style={{ position: "absolute", right: 0 }}
          >
            <span role="img" aria-label="Settings">
              ⚙️
            </span>
          </Button>
        </StatefulPopover>
      </Block>
      <div className="App" style={{ marginLeft: "20px", marginRight: "20px" }}>
        <div
          style={{
            position: "absolute",
            left: "600px",
            top: "80px",
            visibility: showNoteName ? "visible" : "hidden",
          }}
        >
          {notesToPlay.map(([note], index) => {
            return (
              <NoteName
                key={`${note.name}${note.octave}[${index}]`}
                note={note}
              />
            );
          })}
        </div>
        <div
          style={{
            position: "absolute",
            left: "650px",
            top: "80px",
          }}
        >
          {stats &&
            `Note Range: ${minNote.name}${minNote.octave} - ${maxNote.name}${maxNote.octave}`}
          <br />
          {stats && `Notes Played: ${stats.notesPlayed}`}
          <br />
          {stats && `Average Time: ${stats.avgTime}s`}
          <br />
          {stats && `Accuracy: ${stats.accuracy}%`}
        </div>
        <div
          style={{
            position: "relative",
            marginTop: "20px",
            marginBottom: "20px",
          }}
        >
          {notesToPlay.map((noteArr, index) => {
            let note;
            if (noteArr.length === 1) {
              note = noteArr[0];
            }
            return (
              <WholeNote
                key={`${note.name}${note.octave}[${index}]`}
                note={note}
                isSharp={note && note.name && note.name.charAt(1) === "#"}
                offset={index}
              />
            );
          })}
          {notes.map((note, index) => {
            return (
              <WholeNote
                key={`${note.name}${note.octave}`}
                note={note}
                isSharp={note.name.charAt(1) === "#"}
                style={{
                  color: playedCorrectly ? "green" : "red",
                }}
              />
            );
          })}
          <TrebleClef
            style={{ display: "flex" }}
            linesAbove={linesAbove}
            linesBelow={linesBelow}
          />
          <Piano
            noteRange={{ first: minNote.number, last: maxNote.number }}
            activeNotes={notes.map((note) => note.number)}
            playNote={(midiNumber) => {
              const note = getNoteFromNumber(midiNumber);
              if (note) {
                activeNotes = addActiveNote(note, activeNotes);
                setNotes(activeNotes);
              }
            }}
            stopNote={(midiNumber) => {
              const note = getNoteFromNumber(midiNumber);
              if (note) {
                activeNotes = removeActiveNote(note, activeNotes);
                setNotes(activeNotes);
              }
            }}
            width={1000}
            keyboardShortcuts={keyboardShortcuts}
          />
        </div>
      </div>
    </>
  );
}

export default AppWrapper;
