export const demoNotes = [
  [{ number: 52, name: "E", octave: 3 }],
  [{ number: 53, name: "F", octave: 3 }],
  [{ number: 55, name: "G", octave: 3 }],
  [{ number: 57, name: "A", octave: 3 }],
  [{ number: 59, name: "B", octave: 3 }],
  [{ number: 60, name: "C", octave: 4 }],
  [{ number: 62, name: "D", octave: 4 }],
  [{ number: 64, name: "E", octave: 4 }],
  [{ number: 65, name: "F", octave: 4 }],
  [{ number: 67, name: "G", octave: 4 }],
  [{ number: 69, name: "A", octave: 4 }],
  [{ number: 71, name: "B", octave: 4 }],
  [{ number: 72, name: "C", octave: 5 }],
  [{ number: 74, name: "D", octave: 5 }],
  [{ number: 76, name: "E", octave: 5 }],
  [{ number: 77, name: "F", octave: 5 }],
  [{ number: 79, name: "G", octave: 5 }],
  [{ number: 81, name: "A", octave: 5 }],
  [{ number: 83, name: "B", octave: 5 }],
  [{ number: 84, name: "C", octave: 6 }],
  [{ number: 86, name: "D", octave: 6 }],
  [{ number: 88, name: "E", octave: 6 }],
];

export const demoChords = [
  [
    { name: "E", octave: 4 },
    { name: "G", octave: 4 },
    { name: "B", octave: 4 },
  ],
  [
    { name: "F", octave: 4 },
    { name: "A", octave: 4 },
    { name: "C", octave: 5 },
  ],
  [
    { name: "G", octave: 4 },
    { name: "B", octave: 4 },
    { name: "D", octave: 5 },
  ],
  [
    { name: "A", octave: 4 },
    { name: "C", octave: 5 },
    { name: "E", octave: 5 },
  ],
  [
    { name: "B", octave: 4 },
    { name: "D", octave: 5 },
    { name: "F", octave: 5 },
  ],
];
